import './App.css';
import React from 'react';

import { BrowserRouter, Route, Routes } from 'react-router-dom'
import OrgPage from './pages/OrgPage';
import Home from './pages/Home';
function App() {



  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/orgchart" element={<OrgPage />} />
        <Route path="/" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
