import React from 'react';
import logo from '../logo.svg';
function Home() {

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Welcome to my (James Liu) CloudFlare general assesment! Head over to the /orgchart route to see my orgchart!
        </p>
      </header>
    </div>
  );
}

export default Home;



