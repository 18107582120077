import React, { useState, useEffect } from 'react';
import { OrganizationChart } from 'primereact/organizationchart';
import swal from 'sweetalert';

import * as d3 from 'd3';
function OrgPage() {
  const [selection, setSelection] = useState([]);
  const [allEmployees] = useState([])
  const [data, setData] = useState([
    {
      label: 'Temporary Loading Node',
      expanded: true,
      children: []
    }
  ]);

  useEffect(() => {
    d3.json(
      'https://cloudflare-oa-3up.pages.dev/organization-chart'
    ).then((data) => {
      const departmentMap = new Map();
      const departmentManager = new Map();
      const depts = data.organization.departments
      for (let i = 0; i < depts.length; i++) {
        departmentMap[depts[i].name] = depts[i].employees;
        const curEmployees = depts[i].employees;
        for (let j = 0; j < curEmployees.length; j++) {
          allEmployees.push(curEmployees[j]);
          if (curEmployees[j].name === depts[i].managerName) {
            departmentManager[depts[i].name] = depts[i].employees.splice(j, 1)[0];
            j -= 1;
          }
        }
      }

      const managerArray = [];
      for (let department in departmentManager) {
        const employeeArray = [];
        const employees = departmentMap[department];
        for (let i = 0; i < employees.length; i++) {
          employeeArray.push({ label: employees[i].name });
        }
        managerArray.push({ label: departmentManager[department].name, expanded: true, children: employeeArray });
      }
      const newState = [{
        label: 'OrganizationChart',
        expanded: true,
        children: managerArray
      }];

      setData(newState);

    });
  },[]);

  function findAndAlert(name) {
    console.log(allEmployees);
    let queriedEmployee = null;
    for (const employee of allEmployees) {
      if (employee.name === name) {
        queriedEmployee = employee;
        break;
      }
    }
    if (queriedEmployee != null) {
      let employeeInfo = "Department: " + String(queriedEmployee.department) + "\nSalary: " + String(queriedEmployee.salary) + "k\nLocation: " + String(queriedEmployee.office) + "\n";
      let employeeSkills = "Skills: " + queriedEmployee.skills[0] + ", " + queriedEmployee.skills[1] + ", " + queriedEmployee.skills[2] + "\n";
      swal({
        title: queriedEmployee.isManager ? queriedEmployee.name + " (Manager)" : queriedEmployee.name,
        text: employeeInfo + employeeSkills,
      });
    }
    setSelection([])
  }
  return (
    <div>
      <header>Features: Click on a node to display a popup about that individual, also click on the arrow icon to expand or collapse node :D You might need to scroll to see all the employees!</header>
      <OrganizationChart value={data}
        selectionMode="single"
        selection={selection}
        onSelectionChange={(e) => {
          setSelection(e.data);
          findAndAlert(e.data.label)
        }}
        pt={{
          node: ({ context }) => ({
            className: context.selected ? 'border-orange-400 border-round-sm' : 'border-primary-400 border-round-sm'
          })
        }} />
    </div>
  );
}

export default OrgPage;



